import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { MediaAdminLayoutComponent } from './layouts/media-admin-layout/media-admin-layout.component';
import { MediaAuthLayoutComponent } from './layouts/media-auth-layout/media-auth-layout.component';
import {HttpClientModule} from '@angular/common/http';
import {NgxLoadingModule} from 'ngx-loading';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AdminApiRequestsService} from './services/admin-api-requests.service';
import {AuthApiRequestsService} from './services/auth-api-requests.service';
import {AuthGuardService} from "./services/auth-gaurd.service";
import {JwtHelperService, JwtModule} from "@auth0/angular-jwt";
export function tokenGetter() {
  return localStorage.getItem('media_system_token');
}
@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    NgxLoadingModule.forRoot({}),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['example.com'],
        blacklistedRoutes: ['example.com/examplebadroute/']
      }
    })
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    MediaAdminLayoutComponent,
    MediaAuthLayoutComponent,

  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}, AdminApiRequestsService, AuthApiRequestsService, AuthGuardService, JwtHelperService],
  bootstrap: [AppComponent]
})
export class AppModule { }
