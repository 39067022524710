import { Component, OnInit } from '@angular/core';
import {AuthApiRequestsService} from "../../services/auth-api-requests.service";

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/admin/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    { path: '/admin/received-messages', title: 'Received Messages',  icon: 'textsms', class: '' },
    { path: '/admin/sent-messages', title: 'Sent Messages',  icon: 'mail_outline', class: '' },
    { path: '/admin/group-messages', title: 'Group Messages',  icon: 'email', class: '' },
    // { path: '/admin/programs', title: 'Programs',  icon: 'aspect_ratio', class: '' },
    // { path: '/admin/media-recorder', title: 'Media Recorder',  icon: 'settings_voice', class: '' },
    // { path: '/admin/schedules', title: 'Schedules',  icon: 'schedule', class: '' },
    { path: '/admin/shows-time/list', title: 'Shows',  icon: 'list', class: '' },
    // { path: '/admin/fm-receiver', title: 'FM Receiver',  icon: 'radio', class: '' },
    // { path: '/admin/finger-printer', title: 'Finger Printer',  icon: 'fingerprint', class: '' },
    { path: '/admin/contacts/list', title: 'Contacts',  icon: 'contacts', class: '' },
    { path: '/admin/users', title: 'Users',  icon: 'person', class: '' },
    { path: '/admin/settings', title: 'Settings',  icon: 'settings', class: '' },


    // { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    // { path: '/user-profile', title: 'User Profile',  icon:'person', class: '' },
    // { path: '/table-list', title: 'Table List',  icon:'content_paste', class: '' },
    // { path: '/typography', title: 'Typography',  icon:'library_books', class: '' },
    // { path: '/icons', title: 'Icons',  icon:'bubble_chart', class: '' },
    // { path: '/maps', title: 'Maps',  icon:'location_on', class: '' },
    // { path: '/notifications', title: 'Notifications',  icon:'notifications', class: '' },
    // { path: '/upgrade', title: 'Upgrade to PRO',  icon:'unarchive', class: 'active-pro' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(private authService: AuthApiRequestsService) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
  public logOut() {
      this.authService.logOut();
  }
}
