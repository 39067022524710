import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {Routes, RouterModule} from '@angular/router';

import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {MediaAuthLayoutComponent} from './layouts/media-auth-layout/media-auth-layout.component';
import {MediaAdminLayoutComponent} from './layouts/media-admin-layout/media-admin-layout.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'auth/login',
        pathMatch: 'full',
    }, {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
            }]
    },
    {
        path: 'auth',
        component: MediaAuthLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './auth-pages/auth-pages.module#AuthPagesModule'
            }]
    },
    {
        path: 'admin',
        component: MediaAdminLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './admin-pages/admin-pages.module#AdminPagesModule'
            }]
    }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [],
})
export class AppRoutingModule {
}
